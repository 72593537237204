<address>
	Verein Kinderkultur, Luzern<br/>
	Richard-Wagner-Weg 17<br/>
	CH-6004 Luzern<br/>
	Schweiz<br/>
</address>
<p>
	<a href="mailto:mariannehofer@kinderkultur.ch">mariannehofer&#64;kinderkultur.ch</a>
</p>
<p>Marianne Hofer</p>
<h3>Datenschutz</h3>
<p>
	Die Seite verwendet nur Cookies zum einwandfreien Betrieb der Website, dessen Daten nicht an Google und andere Datenhändler weitergegeben werden.
</p>
<p>
	Die Daten welche beim Newsletter-Abonnieren und der Anmeldung für die Figurentheater aufgenommen werden, dienen ausschliesslich für den Versand des Newsletters oder der Anmeldung des Figurentheaters und werden nicht weitergegeben.
</p>
<p>
	Die Adresse kann jederzeit durch den Empfänger aus dem Verteiler gelöscht werden.
</p>
<p>
	Die Betreiberin übernimmt keine Verantwortung für den Inhalt der verlinkten Quellen.
</p>




