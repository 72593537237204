<div class="modal-header">
	<h4 class="modal-title text-center" id="infoModalLabel">Impressum</h4>
	<button
		(click)="activeModal.dismiss('Cross click')"
		aria-describedby="modal-title"
		aria-label="Close button"
		class="close"
		type="button"
	>
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body" ngbAutoFocus>
	<app-impressum></app-impressum>
</div>
