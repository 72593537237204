<header class="header fixed-top header-scrolled" id="header">
  <div class="container">
    <nav class="main-nav navbar navbar-expand-md" role="navigation">
      <button (click)="isMenuCollapsed = !isMenuCollapsed" class="navbar-toggler navbar-dark" type="button">
        <span class="toggle-title">Menu</span>
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        [ngbCollapse]="isMenuCollapsed"
        class="navbar-collapse collapse text-center justify-content-center"
      >
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <a class="nav-link"  (click)="isMenuCollapsed = true"
              routerLink="/">Home</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link"  (click)="isMenuCollapsed = true"
              routerLink="/upcoming" routerLinkActive="active">Aufführungen</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link"  (click)="isMenuCollapsed = true"
              routerLink="/tour" routerLinkActive="active">Tourneestücke</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link"  (click)="isMenuCollapsed = true"
              routerLink="/audio" routerLinkActive="active">Hörspiele</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link"  (click)="isMenuCollapsed = true"
              routerLink="/history" routerLinkActive="active">Vergangene Stücke</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link"  (click)="isMenuCollapsed = true"
              routerLink="/team" routerLinkActive="active">Team</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link"  (click)="isMenuCollapsed = true"
              routerLink="/contact" routerLinkActive="active">Über uns</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
