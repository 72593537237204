<form name="subscribe" method="post" action="/success" netlify>
  <input type="hidden" name="form-name" value="subscribe" />
  <div class="mb-3">
    <label for="first_name" class="form-label">Vorname</label>
    <input type="text" class="form-control" id="first_name" name="first_name" required />
  </div>
  <div class="mb-3">
    <label for="last_name" class="form-label">Nachname</label>
    <input type="text" class="form-control" id="last_name" name="last_name" required/>
  </div>
  <div class="mb-3">
    <label for="last_name" class="form-label">e-Mail</label>
    <input type="email" class="form-control" id="email" name="email" required/>
  </div>
  <button type="submit" class="btn btn-primary">Senden</button>
</form>
